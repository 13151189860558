/* eslint-disable no-shadow */
import {
  fetchSpots,
  updateCurrent,
  updateUpcoming,
  fetchHistory,
} from './availability.service';

export default {
  namespaced: true,
  state: {
    history: [],
    spotsData: {
      programs: [],
      conciergeNote: '',
    },
    updateSpotsSuccess: true,
    unsavedSpotsErrorMsg: '',
  },
  getters: {
    totalSpots: (state) => (state.spotsData.totalCurrentFreeSpots
      + state.spotsData.totalForeseenFreeSpots),
    note: (state) => ({
      contents: state.spotsData.conciergeNote,
      lastUpdated: state.spotsData.conciergeNoteLastUpdated,
    }),
    allSpotsByProgram: (state) => state.spotsData.programs.map((program) => ({
      id: program.id,
      name: program.name,
      currentFreeSpots: program.currentFreeSpots,
      foreseenFreeSpots: program.foreseenFreeSpotsByMonths.map((month) => ({
        inMonths: month.inMonths,
        monthName: month.monthName,
        spots: month.spots,
      })),
    })),
    currentSpotsByProgram: (state) => state.spotsData.programs.map((program) => ({
      id: program.id,
      name: program.name,
      currentFreeSpots: program.currentFreeSpots,
    })),
    upcomingSpotsByProgram: (state) => state.spotsData.programs.map((program) => ({
      id: program.id,
      name: program.name,
      foreseenFreeSpots: program.foreseenFreeSpotsByMonths.map((month) => ({
        inMonths: month.inMonths,
        monthName: month.monthName,
        spots: month.spots,
      })),
    })),
    upcomingMonthLabels: (state) => {
      const { programs } = state.spotsData;
      return programs.length > 0
        ? programs[0].foreseenFreeSpotsByMonths.map((month) => month.monthName)
        : [];
    },
    updateSpotsSuccess: (state) => state.updateSpotsSuccess,
    unsavedSpotsErrorMsg: (state) => state.unsavedSpotsErrorMsg,
  },
  mutations: {
    setSpotsData: (state, spotsData) => {
      state.spotsData = spotsData;
    },
    setHistory: (state, history) => {
      state.history = history;
    },
    setUpdateSpotsStatus: (state, updateSpotsSuccess) => {
      state.updateSpotsSuccess = updateSpotsSuccess;
    },
    setUnsavedSpotsErrorMsg: (state, unsavedSpotsErrorMsg) => {
      state.unsavedSpotsErrorMsg = unsavedSpotsErrorMsg;
    },
  },
  actions: {
    async fetchSpots({ commit, dispatch, rootState }) {
      try {
        if (!rootState.facilityId) {
          return;
        }
        const spotsData = await fetchSpots(rootState.facilityId);
        commit('setSpotsData', spotsData);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not fetch current availability', error }, { root: true });
      }
    },

    async updateCurrent({ commit, dispatch, rootState }, currentSpotsData) {
      try {
        if (!rootState.facilityId) {
          return;
        }

        await updateCurrent(rootState.facilityId, currentSpotsData);
        commit('setUpdateSpotsStatus', true);
      } catch (error) {
        commit('setUpdateSpotsStatus', false);
        commit('setUnsavedSpotsErrorMsg', error);
        dispatch('notifications/addToastError', { text: 'Could not update current availability', error }, { root: true });
      }
    },

    async updateUpcoming({ dispatch, rootState }, upcomingSpotsData) {
      try {
        if (!rootState.facilityId) {
          return;
        }

        await updateUpcoming(rootState.facilityId, upcomingSpotsData);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not update upcoming availability', error }, { root: true });
      }
    },

    async fetchHistory({ commit, rootState, dispatch }) {
      try {
        if (!rootState.facilityId) {
          return;
        }
        const history = await fetchHistory(rootState.facilityId);
        commit('setHistory', history.reverse());
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not fetch history', error }, { root: true });
      }
    },
  },
};
