import { axios, toCamelCase, toSnakeCase } from '@utils';

async function submitResponse(form) {
  const response = await axios.post('/ai_inquiry_responses', toSnakeCase(form));
  return toCamelCase(response.data);
}

export {
  // eslint-disable-next-line import/prefer-default-export
  submitResponse,
};
