import { axios, toCamelCase } from '@utils';

const BASE_URL = '/api/v1/provider_plan';

async function fetchProviderPlanOptions() {
  const { data } = await axios.get('/provider_plans.json');
  return toCamelCase(data);
}

async function fetchProviderPlan() {
  const { data } = await axios.get(BASE_URL);
  return toCamelCase(data);
}

async function updateProviderPlan(planId) {
  const { data } = await axios.put(`${BASE_URL}/${planId}/switch`);
  return toCamelCase(data);
}

async function acceptProviderPlan() {
  const { data } = await axios.put(`${BASE_URL}/accept`);
  return toCamelCase(data);
}

export {
  acceptProviderPlan,
  fetchProviderPlanOptions,
  fetchProviderPlan,
  updateProviderPlan,
};
