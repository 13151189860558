import {
  axios, toCamelCase,
} from '@utils';

const BASE_URL = '/api/v1/legup/leads';

async function providerOptinPaidLeads(paymentMethodId) {
  const url = `${BASE_URL}/provider_optin_paid_leads`;
  const { data } = await axios.put(url, {
    payment_method_id: paymentMethodId,
  });
  return toCamelCase(data);
}

async function removeLead({ childId, centerId }) {
  const url = `${BASE_URL}/remove_lead`;
  const { data } = await axios.put(url, {
    center_id: centerId,
    child_id: childId,
  });
  return toCamelCase(data);
}

export {
  providerOptinPaidLeads, removeLead,
};
