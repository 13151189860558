import { axios, toCamelCase, toSnakeCase } from '@utils';

const BASE_URL = '/api/v1/legup/tour_schedules';
async function getTourSchedules(params) {
  try {
    const { data } = await axios.get(BASE_URL, {
      params: toSnakeCase(params),
    });

    return toCamelCase(data);
  } catch (error) {
    throw new Error(error);
  }
}

async function updateTourSchedules(params) {
  try {
    const response = await axios.post(`${BASE_URL}/batch_set`, toSnakeCase(params));
    return { data: toCamelCase(response.data), success: true };
  } catch (error) {
    if (error.response.status === 400 && error.response.data.errors) {
      return { success: false, errors: error.response.data.errors };
    }
    throw new Error(error);
  }
}

async function getFamilies(tourScheduleId) {
  try {
    const { data } = await axios.get(`${BASE_URL}/${tourScheduleId}/families`);
    return toCamelCase(data);
  } catch (error) {
    throw new Error(error);
  }
}

async function getTourScheduleExceptions(params) {
  try {
    const { data } = await axios.get('/api/v1/legup/tour_schedule_exceptions', {
      params: toSnakeCase(params),
    });

    return toCamelCase(data);
  } catch (error) {
    throw new Error(error);
  }
}

async function createTourScheduleExceptions(params) {
  try {
    const { data } = await axios.post('/api/v1/legup/tour_schedule_exceptions', toSnakeCase(params));
    return toCamelCase(data);
  } catch (error) {
    throw new Error(error);
  }
}

async function updateTourScheduleExceptions(params) {
  try {
    const { exceptionId, ...reqParams } = params;
    const { data } = await axios.put(`/api/v1/legup/tour_schedule_exceptions/${exceptionId}`, toSnakeCase(reqParams));
    return toCamelCase(data);
  } catch (error) {
    throw new Error(error);
  }
}

async function cancelTourScheduleExceptions(tourScheduleExceptionId) {
  try {
    const { data } = await axios.delete(`/api/v1/legup/tour_schedule_exceptions/${tourScheduleExceptionId}`);
    return toCamelCase(data);
  } catch (error) {
    throw new Error(error);
  }
}

export {
  getTourSchedules,
  updateTourSchedules,
  getFamilies,
  getTourScheduleExceptions,
  createTourScheduleExceptions,
  updateTourScheduleExceptions,
  cancelTourScheduleExceptions,
};
