import {
  acceptProviderPlan,
  fetchProviderPlanOptions,
  fetchProviderPlan,
  updateProviderPlan,
} from './provider-plan.service';

export default {
  namespaced: true,
  state: {
    providerPlans: [],
    selectedProviderPlan: {},
  },
  mutations: {
    setProviderPlanOptions: (state, providerPlans) => {
      state.providerPlans = providerPlans.sort((a, b) => {
        if (a.order > b.order) {
          return 1;
        }
        if (a.order < b.order) {
          return -1;
        }
        return 0;
      });
    },

    setProviderPlan(state, providerPlan) {
      state.selectedProviderPlan = providerPlan;
    },
  },
  actions: {
    async fetchProviderPlanOptions({ commit, dispatch }) {
      try {
        const providerPlanOptions = await fetchProviderPlanOptions();
        commit('setProviderPlanOptions', providerPlanOptions);
      } catch (error) {
        dispatch('notifications/addToastError', {
          text: 'Could not fetch provider plan options',
          error,
        }, { root: true });
      }
    },

    async fetchProviderPlan({ commit, dispatch }) {
      try {
        const providerPlan = await fetchProviderPlan();
        commit('setProviderPlan', providerPlan);
      } catch (error) {
        dispatch('notifications/addToastError', {
          text: 'Could not fetch provider plan',
          error,
        }, { root: true });
      }
    },

    async updateAndAcceptProviderPlan({ commit, dispatch }, planId) {
      try {
        const providerPlan = await updateProviderPlan(planId);
        await acceptProviderPlan();
        commit('setProviderPlan', providerPlan);
      } catch (error) {
        dispatch('notifications/addToastError', {
          text: 'Could not update provider plan',
          error,
        }, { root: true });
      }
    },

    async acceptProviderPlan({ commit, dispatch }) {
      try {
        const providerPlan = await acceptProviderPlan();
        commit('setProviderPlan', providerPlan);
      } catch (error) {
        dispatch('notifications/addToastError', {
          text: 'Could not accept provider plan',
          error,
        }, { root: true });
      }
    },
  },
};
