import {
  fetchEnrollments,
  unenroll,
  enroll,
  inviteToReview,
  cancelEnrollmentOffer,
  inviteToAutopay,
  exportEnrollments,
  searchByName,
  editEnrollment,
  editLastEnrollmentDay,
  fetchEnrollment,
  fetchParentInfo,
  fetchAutoPayConfirmationInfo,
} from './legup-enrollments.service';

export default {
  namespaced: true,
  state: {
    enrollments: [],
    searchResult: [],
    page: 1,
    total: 0,
    rowsPerPage: 20,
    currentEnrollment: {},
    parentInfo: {},
  },
  getters: {
    enrollments(state) {
      return state.enrollments;
    },
    currentEnrollment(state) {
      return state.currentEnrollment;
    },
    findParent: (state) => (parentId) => state.parentInfo[parentId],
  },
  mutations: {
    setEnrollments(state, enrollments) {
      state.enrollments = enrollments;
    },
    updateEnrollment(state, { id, enrollment }) {
      const index = state.enrollments.findIndex((e) => e.id === id);
      if (index !== -1) {
        state.enrollments.splice(index, 1, enrollment);
      }
    },
    removeEnrollment(state, id) {
      const index = state.enrollments.findIndex((e) => e.id === id);
      if (index !== -1) {
        state.enrollments.splice(index, 1);
      }
    },
    setSearchResults: (state, result) => {
      state.searchResult = result;
    },
    setPage: (state, page) => {
      state.page = page;
    },
    setTotal: (state, total) => {
      state.total = total;
    },
    setRowsPerPage: (state, rows) => {
      state.rowsPerPage = rows;
    },
    setCurrentEnrollment: (state, enrollment) => {
      state.currentEnrollment = enrollment;
    },
    addParent(state, parent) {
      state.parentInfo = {
        ...state.parentInfo,
        [parent.id]: parent,
      };
    },
  },
  actions: {
    async fetchEnrollments({ dispatch, commit, state }, {
      facilityId, search, sortBy, sortDirection,
    }) {
      try {
        const {
          enrollments, page, total,
        } = await fetchEnrollments(
          facilityId,
          search,
          state.page,
          state.rowsPerPage,
          sortBy,
          sortDirection,
        );
        commit('setEnrollments', enrollments);
        commit('setTotal', total);
        commit('setPage', page);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not load enrollments', error }, { root: true });
      }
    },
    async fetchEnrollment({ dispatch, commit }, { enrollmentId }) {
      try {
        const data = await fetchEnrollment(enrollmentId);
        commit('setCurrentEnrollment', data);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not fetch enrollment', error }, { root: true });
      }
    },
    async exportEnrollments({ dispatch }, { facilityId, search, exportId }) {
      try {
        await exportEnrollments(facilityId, search, exportId);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not export enrollments', error }, { root: true });
        throw new Error(error);
      }
    },
    async enroll({ dispatch, commit }, { enrollmentId, buttonLocation }) {
      try {
        const data = await enroll(enrollmentId, buttonLocation);
        if (data.success) {
          commit('updateEnrollment', { id: data.enrollment.id, enrollment: data.enrollment });
          dispatch('notifications/addToastNotification', { text: 'Success! The enrollment has been logged.' }, { root: true });
        } else {
          dispatch('notifications/addToastError', { text: data.message }, { root: true });
        }
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Failed to enroll', error }, { root: true });
      }
    },

    async unenroll({ dispatch, commit }, {
      enrollmentId, returnChild, lastDay, buttonLocation,
    }) {
      try {
        const data = await unenroll(enrollmentId, returnChild, lastDay, buttonLocation);
        if (data.success && data.enrollment.status === 'pending_removal') {
          commit('updateEnrollment', { id: data.enrollment.id, enrollment: data.enrollment });
          dispatch('notifications/addToastNotification', { text: 'Success! Child has been un-enrolled.' }, { root: true });
        } else if (data.success) {
          commit('removeEnrollment', data.enrollment.id);
          dispatch('notifications/addToastNotification', { text: 'Success! Child has been un-enrolled.' }, { root: true });
        } else {
          dispatch('notifications/addToastError', { text: data.message }, { root: true });
        }
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Failed to un-enroll', error }, { root: true });
      }
    },
    async inviteToReview({ dispatch, commit }, { enrollmentId, buttonLocation }) {
      try {
        const data = await inviteToReview(enrollmentId, buttonLocation);
        if (data.success) {
          commit('updateEnrollment', { id: data.enrollment.id, enrollment: data.enrollment });
          dispatch('notifications/addToastNotification', { text: 'Success! The review request has been sent.' }, { root: true });
        } else {
          dispatch('notifications/addToastError', { text: data.message }, { root: true });
        }
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Failed to sent the invitation', error }, { root: true });
      }
    },
    async inviteToAutopay({ dispatch, commit }, { enrollmentId, params }) {
      try {
        const data = await inviteToAutopay(enrollmentId, params);
        if (data.success) {
          commit('updateEnrollment', { id: data.enrollment.id, enrollment: data.enrollment });
          dispatch('notifications/addToastNotification', { text: 'Success! The invitation has been sent' }, { root: true });
        } else {
          dispatch('notifications/addToastError', { text: data.message }, { root: true });
        }
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Failed to sent the invitation', error }, { root: true });
      }
    },
    // eslint-disable-next-line max-len
    async cancelEnrollmentOffer({ dispatch, commit }, { enrollmentId, returnChild, buttonLocation }) {
      try {
        const data = await cancelEnrollmentOffer(enrollmentId, returnChild, buttonLocation);
        if (data.success) {
          commit('removeEnrollment', data.enrollmentId);
          dispatch('notifications/addToastNotification', { text: 'Success! The enrollment offer has been canceled' }, { root: true });
        } else {
          dispatch('notifications/addToastError', { text: data.message }, { root: true });
        }
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Failed to cancel the enrolment', error }, { root: true });
      }
    },
    async searchByName({ commit, dispatch }, params) {
      try {
        const { results } = await searchByName(params);
        commit('setSearchResults', results);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not search.', error }, { root: true });
      }
    },
    async editEnrollment({ dispatch, commit }, {
      enrollmentId, amount, startDate, endDate, frequency, autopay,
    }) {
      try {
        const data = await editEnrollment(
          enrollmentId,
          amount,
          startDate,
          endDate,
          frequency,
          autopay,
        );
        if (data.success) {
          commit('updateEnrollment', { id: data.enrollment.id, enrollment: data.enrollment });
          dispatch('notifications/addToastNotification', { text: 'Success! The enrollment has been updated.' }, { root: true });
        } else {
          dispatch('notifications/addToastError', { text: data.message }, { root: true });
        }
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Failed to edit the enrolment', error }, { root: true });
      }
    },

    async editLastEnrollmentDay({ dispatch, commit }, { enrollmentId, lastDay }) {
      try {
        const data = await editLastEnrollmentDay(enrollmentId, lastDay);
        if (data.success) {
          if (data.enrollment.status === 'unenrolled') {
            commit('removeEnrollment', data.enrollmentId);
          } else {
            commit('updateEnrollment', { id: data.enrollment.id, enrollment: data.enrollment });
          }
          dispatch('notifications/addToastNotification', { text: 'Success! The enrollment has been updated' }, { root: true });
        } else {
          dispatch('notifications/addToastError', { text: data.message }, { root: true });
        }
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Failed to edit the enrolment', error }, { root: true });
      }
    },

    async fetchParentInfo({ dispatch, commit }, parentId) {
      try {
        const data = await fetchParentInfo(parentId);
        commit('addParent', data);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not fetch parent info', error }, { root: true });
      }
    },

    async fetchAutoPayConfirmationInfo({ dispatch }, params) {
      let data = null;
      try {
        data = await fetchAutoPayConfirmationInfo(params);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not fetch autopay info', error }, { root: true });
      }
      return data;
    },
  },
};
