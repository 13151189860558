import {
  fetchEnrollments,
  fetchStartEnrollment,
  saveEnrollment,
  saveDocument,
} from './enrollments.service';

export default {
  namespaced: true,
  state: {
    enrollments: [],
    tourEnabled: false,
    depositAmount: 0,
    documents: [],
    enrollmentDataLoaded: false,
  },
  mutations: {
    setEnrollments: (state, enrollments) => {
      state.enrollments = enrollments;
    },

    setTourEnabled: (state, tourEnabled) => {
      state.tourEnabled = tourEnabled;
    },

    setDocuments: (state, documents) => {
      state.documents = documents;
    },

    setEnrollmentDataLoaded: (state, enrollmentDataLoaded) => {
      state.enrollmentDataLoaded = enrollmentDataLoaded;
    },

    setDepositAmount: (state, depositAmount) => {
      state.depositAmount = depositAmount;
    },
  },
  actions: {
    async fetchEnrollments({ commit, dispatch, rootState }) {
      try {
        if (!rootState.facilityId) {
          return;
        }

        const enrollments = await fetchEnrollments(rootState.facilityId);
        commit('setEnrollments', enrollments);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not fetch enrollments', error }, { root: true });
      }
    },

    async fetchStartEnrollment(
      {
        commit,
        dispatch,
        state,
      },
      { providerId, facilityId },
    ) {
      try {
        if (state.enrollmentDataLoaded) {
          return;
        }

        const startEnrollment = await fetchStartEnrollment(providerId, facilityId);

        commit('setTourEnabled', startEnrollment.tourEnabled);
        commit('setDocuments', startEnrollment.documents);
        commit('setDepositAmount', startEnrollment.depositAmount);
        commit('setEnrollmentDataLoaded', true);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not start enrollment', error }, { root: true });
      }
    },

    async saveEnrollment({ dispatch }, { centerId, enrollmentData, prevEnrollmentId }) {
      try {
        const enrollment = await saveEnrollment(centerId, enrollmentData, prevEnrollmentId);

        return enrollment.enrollmentIds;
      } catch (error) {
        if (error.response?.data?.message) {
          dispatch('notifications/addToastError', { text: error.response.data.message }, { root: true });
        } else {
          dispatch('notifications/addToastError', { text: 'Could not save enrollment', error }, { root: true });
        }
      }

      return null;
    },

    async saveDocument({ commit, dispatch, state }, { providerId, formData }) {
      try {
        const document = await saveDocument(providerId, formData);
        commit('setDocuments', [document, ...state.documents]);
        return document.id;
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not save document', error }, { root: true });
      }

      return null;
    },
  },
};
