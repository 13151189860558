import { axios, toCamelCase, toSnakeCase } from '@utils';

async function fetchEnrollments(facilityId) {
  const response = await axios.get(`/api/v1/enrollments?child_care_facility_id=${facilityId}`);
  return toCamelCase(response.data);
}

async function fetchStartEnrollment(providerId, facilityId) {
  const response = await axios.get(`/api/v1/providers/${providerId}/enrollment_details?child_care_facility_id=${facilityId}`);
  return toCamelCase(response.data);
}

async function saveEnrollment(centerId, enrollmentData, prevEnrollmentId) {
  const payload = toSnakeCase({ ...enrollmentData, centerId, prevEnrollmentId });
  const response = await axios.post('/api/v1/legup/enrollments', payload);
  return toCamelCase(response.data);
}

async function saveDocument(providerId, formData) {
  const response = await axios.post(`/api/v1/providers/${providerId}/upload_document`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return toCamelCase(response.data);
}

export {
  // eslint-disable-next-line
  fetchEnrollments,
  fetchStartEnrollment,
  saveEnrollment,
  saveDocument,
};
