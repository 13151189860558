import { axios, toCamelCase, toSnakeCase } from '@utils';

const BASE_URL = '/api/v1/payment_collections';

async function fetchBillingPlans(
  facilityId,
  searchText,
  filter,
  page,
  rowsPerPage,
  sortBy,
  sortDirection,
) {
  const { data } = await axios.get(BASE_URL, {
    params: {
      facility_id: facilityId,
      rows_per_page: rowsPerPage,
      sort_by: sortBy,
      sort_direction: sortDirection,
      search: searchText,
      filter,
      page,
    },
  });
  return toCamelCase(data);
}

async function search(params) {
  const { data } = await axios.get(`${BASE_URL}/search`, { params: toSnakeCase(params) });

  return data;
}

async function updateBillingPlan(id, params) {
  const url = `${BASE_URL}/${id}`;

  const { data } = await axios.put(url, { payment_collection: toSnakeCase(params) });
  return toCamelCase(data);
}

async function fetchBillingPlan(id) {
  const url = `${BASE_URL}/${id}`;

  const { data } = await axios.get(url);
  return toCamelCase(data);
}

async function dismissSetupAlert(billingIds, reInvite) {
  const url = `${BASE_URL}/dismiss_setup_alert`;

  const { data } = await axios.put(url, {
    payment_collection_ids: billingIds,
    re_invite: reInvite,
  });
  return toCamelCase(data);
}

export {
  // eslint-disable-next-line
  fetchBillingPlans,
  search,
  updateBillingPlan,
  fetchBillingPlan,
  dismissSetupAlert,
};
