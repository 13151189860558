import { axios, toCamelCase, toSnakeCase } from '@utils';

async function fetchFeesSettings(providerId) {
  const response = await axios.get(`/api/v1/providers/${providerId}/fees.json`);
  return toCamelCase(response.data);
}

async function submitFeesSettings(providerId, feesSettingsData) {
  const response = await axios.put(`/api/v1/providers/${providerId}/fees.json`, toSnakeCase(feesSettingsData));
  return toCamelCase(response.data);
}

export {
  // eslint-disable-next-line import/prefer-default-export
  fetchFeesSettings,
  submitFeesSettings,
};
