import { axios, toCamelCase, toSnakeCase } from '@utils';

const BASE_URL = '/api/v1/legup/families';

async function fetchFamilies({
  searchText, page, rowsPerPage, sortBy, sortDirection, programs, status, leadsOnly,
}) {
  const { data } = await axios.get(BASE_URL, {
    params: toSnakeCase({
      rowsPerPage,
      sortBy,
      sortDirection,
      searchText,
      page,
      programs,
      status,
      leadsOnly,
    }),
  });
  return toCamelCase(data);
}

async function exportFamilies(searchText, programs, status, exportId) {
  const url = `${BASE_URL}/export`;
  await axios.get(url, {
    params: toSnakeCase({
      searchText,
      programs,
      status,
      exportId,
    }),
  });
}

async function fetchFamily(parentId) {
  const { data } = await axios.get(`${BASE_URL}/${parentId}`);
  return toCamelCase(data);
}

async function updateSubsidies(parentId, subsidies) {
  const { data } = await axios.post(`${BASE_URL}/${parentId}/update_subsidies`, toSnakeCase({ subsidies }));
  return toCamelCase(data);
}

async function updateParents(parents) {
  const { data } = await axios.post(`${BASE_URL}/update_parents`, toSnakeCase({ parents }));
  return toCamelCase(data);
}

async function updateChildren(children) {
  const { data } = await axios.post(`${BASE_URL}/update_children`, toSnakeCase({ children }));
  return toCamelCase(data);
}

// eslint-disable-next-line
export {
  fetchFamilies,
  exportFamilies,
  fetchFamily,
  updateSubsidies,
  updateParents,
  updateChildren,
};
