import { axios, toCamelCase, toSnakeCase } from '@utils';

async function fetchProviderInfo(providerId) {
  const response = await axios.get(`/api/v1/providers/${providerId}/business_info.json`);
  return toCamelCase(response.data);
}

async function saveProviderInfo(providerData) {
  const response = await axios.post('/business', toSnakeCase(providerData));
  return toCamelCase(response.data);
}

export {
  // eslint-disable-next-line import/prefer-default-export
  fetchProviderInfo,
  saveProviderInfo,
};
