import {
  getWaitlistSpots,
  getWaitlistSpotDetails,
  sendWaitlistInvite,
  fetchOfferPreview,
  fetchAgeGroupsAndPrograms,
  searchSpotsByName,
  markUnresponsive,
  markEnrolled,
  reactivate,
} from './waitlist.service';

export default {
  namespaced: true,

  state: {
    filters: {},
    filterCounts: {},
    spots: [],
    spotDetails: {},
    loadingSpots: false,
    loadingSpot: false,
    page: 1,
    searchText: '',
    rowsPerPage: 20,
    totalSearchResults: 120,
    sort: 'position',
    activeFilters: {},
    editProviderNotes: false,
    facilityId: null,
    offerPreview: [],
    ageGroupsAndPrograms: [],
    searchResult: [],
  },

  getters: {
    filters: (state, getters) => {
      const filters = {};

      Object.keys(state.filters).forEach((key) => {
        const filter = state.filters[key];

        const getFilterCount = (type, id) => {
          const filterCount = state.filters[type]
            .options
            .find((o) => o.id === id)
            .count;

          if (type !== 'agegroups' && getters.cleanActiveFilters.agegroups) {
            return state.filterCounts[type] ? state.filterCounts[type][id] : filterCount;
          }

          return filterCount;
        };

        const options = filter.options.map((option) => {
          const count = getFilterCount(key, option.id);
          return { ...option, count };
        });
        filters[key] = { ...filter, options };
      });

      Object.keys(filters).forEach((key) => {
        filters[key].options = filters[key].options.reduce((options, option) => {
          const existingOption = options.find((o) => o.name === option.name);
          if (existingOption) {
            existingOption.count += option.count;
            existingOption.id += `,${option.id}`;
          } else {
            options.push(option);
          }

          return options;
        }, []);
      });

      return filters;
    },

    activeFilters: (state) => {
      const defaultFilters = {};

      Object.keys(state.filters).forEach((key) => {
        if (state.activeFilters[key] === undefined) {
          defaultFilters[key] = state.filters[key].default;
        }
      });

      return { ...state.activeFilters, ...defaultFilters };
    },

    cleanActiveFilters: (state) => {
      const cleanFilters = {};

      Object.keys(state.activeFilters).forEach((key) => {
        if (state.activeFilters[key] !== undefined) {
          if (Array.isArray(state.activeFilters[key])) {
            const activeFilters = state.activeFilters[key];
            cleanFilters[key] = activeFilters?.map((option) => option.toString().split(',')).flat();
          } else {
            cleanFilters[key] = state.activeFilters[key];
          }
        }
      });

      return cleanFilters;
    },

    spotDetails: (state) => (id) => state.spotDetails[id],

    filterOptionsWithCounts: (state, getters) => (type) => getters.filters[type].options.filter(
      (option) => option.count > 0,
    ),

    filterOptionsWithoutCounts: (state, getters) => (type) => getters.filters[type].options.filter(
      (option) => option.count === 0,
    ),
  },

  mutations: {
    setFacilityId: (state, facilityId) => {
      state.facilityId = facilityId;
    },

    setFilters: (state, filters) => {
      state.filters = filters;
    },

    setFilterCounts: (state, filterCounts) => {
      state.filterCounts = filterCounts;
    },

    setSpots: (state, spots) => {
      state.spots = spots;
    },

    setLoadingSpots: (state, loading) => {
      state.loadingSpots = loading;
    },

    setLoadingSpot: (state, loading) => {
      state.loadingSpot = loading;
    },

    setPage: (state, page) => {
      state.page = page;
    },

    setSearchText: (state, searchText) => {
      state.searchText = searchText;
    },

    setRowsPerPage: (state, rowsPerPage) => {
      state.rowsPerPage = rowsPerPage;
    },

    setTotalSearchResults: (state, totalSearchResults) => {
      state.totalSearchResults = totalSearchResults;
    },

    setSort: (state, sort) => {
      state.sort = sort;
    },

    setActiveFilters: (state, activeFilters) => {
      state.activeFilters = activeFilters;
    },

    setSpotDetail: (state, spotDetail) => {
      state.spotDetails = { ...state.spotDetails, [spotDetail.id]: spotDetail };
    },

    setProviderNotes: (state, { id, notes }) => {
      state.spotDetails[id].providerNotes = notes;
    },

    setSelectedDate: (state, { id, date }) => {
      state.spotDetails[id].preferredDate = date;
    },

    setSelectedSchedules: (state, { id, scheduleIds }) => {
      state.spotDetails[id].selectedSchedules = scheduleIds;
    },

    setSchedulesLongName: (state, { id, scheduleNames }) => {
      state.spotDetails[id].preferredSchedulesLongName = scheduleNames;
    },

    setEditProviderNotes: (state, editProviderNotes) => {
      state.editProviderNotes = editProviderNotes;
    },

    setOfferPreview: (state, offerPreview) => {
      state.offerPreview = offerPreview;
    },

    setAgeGroupsAndPrograms: (state, data) => {
      state.ageGroupsAndPrograms = data;
    },

    setSearchResults: (state, result) => {
      state.searchResult = result;
    },

    setSpotAnswers: (state, { answers, id }) => {
      state.spotDetails[id].customAnswers = answers;
    },
  },

  actions: {
    async getSpots({ commit, dispatch, state }, params) {
      commit('setLoadingSpots', true);

      try {
        const spotsData = await getWaitlistSpots(params);

        commit('setSpots', spotsData.result || []);
        commit('setTotalSearchResults', spotsData.total);
        commit('setPage', spotsData.page);

        if (params.page === 1 && spotsData.filters && (Object.keys(state.filters).length === 0)) {
          commit('setFilters', spotsData.filters);
        } else if (spotsData.filters) {
          const filterCounts = Object.keys(spotsData.filters).reduce((counts, key) => ({
            ...counts,
            [key]: spotsData.filters[key].options.reduce((acc, option) => ({
              ...acc,
              [option.id]: option.count,
            }), {}),
          }
          ), {});

          commit('setFilterCounts', filterCounts);
        }
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not fetch spots', error }, { root: true });
      }

      commit('setLoadingSpots', false);
    },

    async getSpotDetails({ commit, dispatch }, id) {
      commit('setLoadingSpot', true);

      try {
        const spotDetails = await getWaitlistSpotDetails(id);
        commit('setSpotDetail', spotDetails);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not fetch spot data', error }, { root: true });
      }

      commit('setLoadingSpot', false);
    },

    async sendWaitlistInvite({ dispatch }, params) {
      try {
        await sendWaitlistInvite(params);
        dispatch(
          'notifications/addToastSuccess',
          { text: 'Invitation sent!' },
          { root: true },
        );
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not send the invitation. Refresh the page to try again, or contact concierge@kinside.com for more help.', error }, { root: true });
      }
    },

    async fetchOfferPreview({ commit, dispatch }, params) {
      try {
        const previewDetails = await fetchOfferPreview(params);
        commit('setOfferPreview', previewDetails);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not fetch offer preview information', error }, { root: true });
      }
    },

    async fetchAgeGroupsAndPrograms({ commit, dispatch }, { facilityId }) {
      try {
        const data = await fetchAgeGroupsAndPrograms(facilityId);
        commit('setAgeGroupsAndPrograms', data);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not fetch offer programs', error }, { root: true });
      }
    },

    reset({ commit }) {
      commit('setSpots', []);
      commit('setFilterCounts', {});
      commit('setFilters', {});
      commit('setPage', 1);
      commit('setSearchText', '');
      commit('setTotalSearchResults', 0);
      commit('setActiveFilters', {});
      commit('setSort', 'position');
    },

    async searchSpotsByName({ commit, dispatch }, params) {
      try {
        const { results } = await searchSpotsByName(params);
        commit('setSearchResults', results);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not search.', error }, { root: true });
      }
    },

    async markUnresponsive({ dispatch, commit, state }, id) {
      try {
        await markUnresponsive(id);

        commit('setSpots', state.spots.map((spot) => {
          const newSpot = { ...spot };

          if (spot.id === id) {
            newSpot.notResponding = true;
          }

          return newSpot;
        }));

        dispatch('notifications/addToastSuccess', { text: 'Marked as unresponsive!' }, { root: true });
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not mark as unresponsive', error }, { root: true });
      }
    },

    async markEnrolled({ dispatch, commit, state }, { id, decrementFilter }) {
      try {
        await markEnrolled(id);
        dispatch('removeSpot', id);

        if (decrementFilter) {
          const filters = { ...state.filters };
          filters.status = {
            ...filters.status,
            options: filters.status.options.map((status) => {
              if (status.id === 'spot_offered') {
                return { ...status, count: status.count - 1 };
              }

              return status;
            }),
          };
          commit('setFilters', filters);
        }

        dispatch('notifications/addToastSuccess', { text: 'Marked as enrolled!' }, { root: true });
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not mark as enrolled', error }, { root: true });
      }
    },

    removeSpot({ commit, state }, id) {
      commit('setSpots', state.spots.filter((spot) => spot.id !== id));
    },

    async reactivate({ dispatch }, id) {
      try {
        await reactivate(id);

        dispatch('notifications/addToastSuccess', { text: 'Reactivated waitlist spot!' }, { root: true });
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Unable to match child to an age group. Cannot reactivate waitlist spot.', error }, { root: true });

        throw new Error(error);
      }
    },
  },
};
