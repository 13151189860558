/* eslint-disable no-shadow */
import {
  fetchFeesSettings, submitFeesSettings,
} from './provider-fees.service';

const state = {
  feesSettings: {
    waitlist_fee: null,
    waive_fees_with_subsidy: null,
    kinside_waitlist_fee: null,
    kinside_transation_fee: null,
  },
};

const getters = {
  feesSettings: (state) => state.feesSettings,
};

const mutations = {
  setFeesSettings: (state, feesSettings) => {
    state.feesSettings = feesSettings;
  },
};

const actions = {
  async fetchFeesSettings({ commit, dispatch }, id) {
    try {
      if (!id) {
        dispatch('notifications/addToastError', { text: 'Could not fetch fees settings' }, { root: true });
        return;
      }
      const providerFees = await fetchFeesSettings(id);
      commit('setFeesSettings', providerFees);
    } catch (error) {
      dispatch('notifications/addToastError', { text: 'Could not fetch fees settings', error }, { root: true });
    }
  },
  async submitFeesSettings({ commit, dispatch }, { id, feesSettings }) {
    try {
      if (!id) {
        dispatch('notifications/addToastError', { text: 'Could not submit fees settings' }, { root: true });
        return false;
      }
      const providerFees = await submitFeesSettings(id, feesSettings);
      commit('setFeesSettings', providerFees);
      return true;
    } catch (error) {
      dispatch('notifications/addToastError', { text: 'Could not submit fees settings', error }, { root: true });
      return false;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
