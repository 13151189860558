import {
  fetchPaymentMethods,
  addBankAccountPaymentMethod,
} from './bankAccounts.service';

import { addArrayToDictionary } from '../../utils';

export default {
  namespaced: true,
  state: {
    paymentMethods: {},
  },
  getters: {
    paymentMethodsAsArray(state) {
      return Object.values(state.paymentMethods)
        .filter((method) => method !== undefined && !method.deleted);
    },
  },
  mutations: {
    addPaymentMethods: (state, methods) => {
      state.paymentMethods = { ...addArrayToDictionary(methods, state.paymentMethods) };
    },
  },
  actions: {
    async fetchPaymentMethods({ commit, state, dispatch }) {
      try {
        const isPaymentMethodsEmpty = Object.keys(state.paymentMethods).length === 0;
        if (isPaymentMethodsEmpty) {
          const paymentMethods = await fetchPaymentMethods();
          commit('addPaymentMethods', paymentMethods);
        }
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not fetch payment methods', error }, { root: true });
        throw new Error();
      }
    },

    async addBankAccount({ commit, dispatch }, methodData) {
      try {
        // eslint-disable-next-line max-len
        const addedMethod = await addBankAccountPaymentMethod(methodData.publicToken, methodData.accountId);
        commit('addPaymentMethods', [addedMethod]);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not add a new bank account', error }, { root: true });
        throw new Error();
      }
    },
  },
};
