import { axios, toCamelCase } from '@utils';

async function fetchCalendars() {
  const response = await axios.get('/api/v1/calendars.json');
  return toCamelCase(response.data);
}

async function deleteCalendar(id) {
  const response = await axios.delete(`/api/v1/calendars/${id}.json`);
  return toCamelCase(response.data);
}

export {
  fetchCalendars,
  deleteCalendar,
};
