import { axios, toCamelCase, toSnakeCase } from '@utils';

const BASE_PATH = '/api/v1/child_care_facilities/';

async function fetchSpots(facilityId) {
  const response = await axios.get(
    `${BASE_PATH}${facilityId}/availability.json`,
  );
  return toCamelCase(response.data);
}

async function updateCurrent(facilityId, availabilityData) {
  const response = await axios.put(
    `${BASE_PATH}${facilityId}/availability.json`,
    toSnakeCase(availabilityData),
  ).catch((error) => {
    if (error.response) {
      throw error.response.data.errors;
    }
  });
  return toCamelCase(response.data);
}

async function updateUpcoming(facilityId, upcomingSpotsData) {
  const response = await axios.put(
    `${BASE_PATH}${facilityId}/availability/foreseen.json`,
    toSnakeCase(upcomingSpotsData),
  );
  return toCamelCase(response.data);
}

async function fetchHistory(facilityId) {
  const response = await axios.get(
    `${BASE_PATH}${facilityId}/availability/history.json`,
  );
  return toCamelCase(response.data || []);
}

export {
  fetchSpots,
  updateCurrent,
  updateUpcoming,
  fetchHistory,
};
