import {
  axios, toCamelCase, toSnakeCase,
} from '@utils';

const BASE_URL = '/api/v1/legup/tours';

async function fetchTours(params) {
  const { data } = await axios.get(BASE_URL, {
    params: toSnakeCase(params),
  });
  return toCamelCase(data);
}

async function exportTours(params) {
  const url = `${BASE_URL}/export`;
  await axios.get(url, {
    params: toSnakeCase(params),
  });
}

async function searchByName(params) {
  const { data } = await axios.get(`${BASE_URL}/search_by_name`, { params: toSnakeCase(params) });

  return data;
}

async function createOpenHouse(params) {
  const newParams = { ...params, isOpenHouse: true };
  const { data } = await axios.post('/api/v1/legup/tour_schedules', toSnakeCase(newParams));
  return toCamelCase(data);
}

async function updateOpenHouse(params) {
  const { data } = await axios.put(`/api/v1/legup/tour_schedules/${params.tourScheduleId}`, toSnakeCase(params));
  return toCamelCase(data);
}

async function cancelOpenHouse(params) {
  const { data } = await axios.delete(`/api/v1/legup/tour_schedules/${params.tourScheduleId}`, toSnakeCase(params));
  return toCamelCase(data);
}

async function createTour(params) {
  const { data } = await axios.post(`${BASE_URL}/schedule`, toSnakeCase(params));
  return toCamelCase(data);
}

async function fetchNewToursCount() {
  const response = await axios.get(`${BASE_URL}/new_count`);
  return toCamelCase(response.data);
}

async function markSeen(body) {
  const response = await axios.put(
    `${BASE_URL}/mark_seen`,
    toSnakeCase(body),
  );

  return response;
}

async function inviteToOpenHouse(params) {
  const { data } = await axios.post('/api/v1/legup/open_house_invites', toSnakeCase(params));
  return toCamelCase(data);
}

// eslint-disable-next-line
export {
  fetchTours,
  exportTours,
  searchByName,
  createOpenHouse,
  updateOpenHouse,
  cancelOpenHouse,
  fetchNewToursCount,
  markSeen,
  createTour,
  inviteToOpenHouse,
};
