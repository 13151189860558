import { minutesFromMidnightToTime, timeToMinutesFromMidnight } from '@utils';
import {
  getTourSchedules,
  updateTourSchedules,
  getFamilies,
  getTourScheduleExceptions,
  createTourScheduleExceptions,
  updateTourScheduleExceptions,
  cancelTourScheduleExceptions,
} from './tours-schedules.service';

export default {
  namespaced: true,

  state: {
    capacity: 1,
    duration: 30,
    tourSchedules: {},
    openHouse: [],
    virtualLink: undefined,
    errors: false,
    families: [],
    exceptions: [],
  },

  mutations: {
    setTourSchedules: (state, { schedules, virtualLink }) => {
      state.capacity = schedules[0]?.capacity || 1;
      state.duration = schedules[0]?.duration || 30;
      state.virtualLink = virtualLink;

      const tourSchedules = {};
      schedules.forEach((schedule) => {
        const startTime = minutesFromMidnightToTime(schedule.startTime);
        const endTime = minutesFromMidnightToTime(schedule.endTime);
        const formattedSchedule = { ...schedule, startTime, endTime };
        if (tourSchedules[schedule.dayOfWeek]) {
          tourSchedules[schedule.dayOfWeek].push(formattedSchedule);
        } else {
          tourSchedules[schedule.dayOfWeek] = [formattedSchedule];
        }
      });

      state.tourSchedules = tourSchedules;
    },

    setOpenHouse: (state, schedules) => {
      state.openHouse = schedules;
    },

    setErrors: (state, hasErrors) => {
      state.errors = hasErrors;
    },

    setFamilies: (state, families) => {
      state.families = families;
    },

    setTourScheduleExceptions: (state, days) => {
      state.exceptions = days;
    },
  },

  actions: {
    async getTourSchedules({ commit, dispatch }, params) {
      try {
        const { tourSchedules, virtualLink } = await getTourSchedules(params);
        if (params.openHouse) {
          commit('setOpenHouse', tourSchedules);
        } else {
          commit('setTourSchedules', {
            schedules: tourSchedules || [],
            virtualLink,
          });
        }
      } catch (error) {
        dispatch(
          'notifications/addToastError',
          { text: 'Could not fetch tour schedules', error },
          { root: true },
        );
      }
    },

    async updateTourSchedules({
      state, commit, dispatch,
    }, { schedules, centerId, virtualLink }) {
      commit('setErrors', false);
      const schedulesParam = [];
      try {
        Object.keys(schedules).forEach((day) => {
          schedules[day].forEach((schedule) => {
            schedulesParam.push({
              ...schedule,
              dayOfWeek: day,
              duration: state.duration,
              capacity: state.capacity,
              startTime: timeToMinutesFromMidnight(schedule.startTime),
              endTime: timeToMinutesFromMidnight(schedule.endTime),
            });
          });
        });
        const response = await updateTourSchedules({
          centerId,
          schedules: schedulesParam,
          virtualLink,
        });
        if (response.success) {
          commit('setTourSchedules', {
            schedules: response.data.tourSchedules || [],
            virtualLink: response.data.virtualLink,
          });
          dispatch(
            'notifications/addToastSuccess',
            { text: 'Tour schedules updated' },
            { root: true },
          );
        } else {
          commit('setErrors', true);
          dispatch(
            'notifications/addToastError',
            { text: response.errors[0], error: response.errors[0] },
            { root: true },
          );
        }
      } catch (error) {
        commit('setErrors', true);
        dispatch(
          'notifications/addToastError',
          { text: 'Could not update tour schedules', error },
          { root: true },
        );
      }
    },

    async getFamilies({ commit, dispatch }, tourScheduleId) {
      try {
        const { data } = await getFamilies(tourScheduleId);
        commit('setFamilies', data);
      } catch (error) {
        dispatch(
          'notifications/addToastError',
          { text: 'Could not fetch families', error },
          { root: true },
        );
      }
    },

    async getTourScheduleExceptions({ commit, dispatch }, params) {
      try {
        const data = await getTourScheduleExceptions(params);
        commit('setTourScheduleExceptions', data);
      } catch (error) {
        dispatch(
          'notifications/addToastError',
          { text: 'Could not fetch unavailable dates', error },
          { root: true },
        );
      }
    },

    async createUnavailableDate({ dispatch }, params) {
      try {
        const response = await createTourScheduleExceptions(params);
        if (response.success) {
          dispatch('notifications/addToastSuccess', { text: 'Created successfully' }, { root: true });
        }
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not create unavailable date', error }, { root: true });
      }
    },

    async updateUnavailableDate({ dispatch }, params) {
      try {
        const response = await updateTourScheduleExceptions(params);
        if (response.success) {
          dispatch('notifications/addToastSuccess', { text: 'Updated successfully' }, { root: true });
        }
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not update unavailable date', error }, { root: true });
      }
    },

    async cancelUnavailableDate({ dispatch }, tourScheduleExceptionId) {
      try {
        const response = await cancelTourScheduleExceptions(tourScheduleExceptionId);
        if (response.success) {
          dispatch('notifications/addToastSuccess', { text: 'Success! Unavailability canceled' }, { root: true });
        }
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not cancel unavailable date', error }, { root: true });
      }
    },
  },
};
