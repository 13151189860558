import { fetchHistory, fetchCurrentFailedPayments, dismissFailedPaymentsAlert } from './payment-history.service';

export default {
  namespaced: true,
  state: {
    history: [],
    page: 1,
    total: 0,
    rowsPerPage: 20,
    sortBy: 'date',
    sortDirection: 'desc',
    stats: {},
    status: undefined,
  },
  mutations: {
    setHistory(state, history) {
      state.history = history;
    },

    setPage: (state, page) => {
      state.page = page;
    },

    setTotal: (state, total) => {
      state.total = total;
    },

    setRowsPerPage: (state, rows) => {
      state.rowsPerPage = rows;
    },

    setSortBy: (state, sortBy) => {
      state.sortBy = sortBy;
    },

    setSortDirection: (state, sortDirection) => {
      state.sortDirection = sortDirection;
    },

    setStats(state, stats) {
      state.stats = stats;
    },
  },
  actions: {
    async fetchHistory({ dispatch, commit, state }, {
      facilityId, searchText, category, startDate, endDate, status,
    }) {
      try {
        const {
          paymentRequests,
          page,
          total,
          stats,
        } = await fetchHistory({
          facilityId,
          searchText,
          page: state.page,
          rowsPerPage: state.rowsPerPage,
          sortBy: state.sortBy,
          sortDirection: state.sortDirection,
          category,
          startDate,
          endDate,
          status,
        });
        commit('setHistory', paymentRequests);
        commit('setTotal', total);
        commit('setPage', page);
        commit('setStats', stats);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not load payment history', error }, { root: true });
      }
    },
    async fetchCurrentFailedPayments({ dispatch }, facilityId) {
      let paymentRequests = {};
      try {
        paymentRequests = await fetchCurrentFailedPayments(facilityId);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not load payments', error }, { root: true });
      }
      return paymentRequests;
    },
    async dismissFailedPaymentsAlert({ dispatch }, facilityId) {
      try {
        await dismissFailedPaymentsAlert(facilityId);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not dismiss alert', error }, { root: true });
      }
    },
  },
};
