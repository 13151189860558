import {
  fetchFamilies,
  exportFamilies,
  fetchFamily,
  updateSubsidies,
  updateParents,
  updateChildren,
} from './families.service';

export default {
  namespaced: true,
  state: {
    families: [],
    page: 1,
    total: 0,
    rowsPerPage: 20,
    sortBy: 'childName',
    sortDirection: 'asc',
    selectedFamily: {},
  },
  mutations: {
    setFamilies(state, families) {
      state.families = families;
    },

    setPage: (state, page) => {
      state.page = page;
    },

    setTotal: (state, total) => {
      state.total = total;
    },

    setRowsPerPage: (state, rows) => {
      state.rowsPerPage = rows;
    },

    setSortBy: (state, sortBy) => {
      state.sortBy = sortBy;
    },

    setSortDirection: (state, sortDirection) => {
      state.sortDirection = sortDirection;
    },

    setSelectedFamily: (state, selectedFamily) => {
      state.selectedFamily = selectedFamily;
    },
    removeFamily(state, childId) {
      state.families = state.families.filter((family) => family.child.id !== childId);
    },
  },
  actions: {
    async fetchFamilies({ dispatch, commit, state }, {
      searchText, programs, status, leadsOnly,
    }) {
      try {
        const {
          families, page, total,
        } = await fetchFamilies({
          searchText,
          page: state.page,
          rowsPerPage: state.rowsPerPage,
          sortBy: state.sortBy,
          sortDirection: state.sortDirection,
          programs,
          status,
          leadsOnly,
        });
        commit('setFamilies', families);
        commit('setTotal', total);
        commit('setPage', page);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not load families', error }, { root: true });
      }
    },

    async fetchFamily({ dispatch, commit }, parentId) {
      try {
        const data = await fetchFamily(parentId);
        commit('setSelectedFamily', data);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not load families', error }, { root: true });
      }
    },

    async exportFamilies({ dispatch }, {
      searchText, programs, status, exportId,
    }) {
      try {
        await exportFamilies(searchText, programs, status, exportId);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not export families', error }, { root: true });
        throw new Error(error);
      }
    },

    async updateSubsidies({ dispatch }, { parentId, subsidies }) {
      try {
        await updateSubsidies(parentId, subsidies);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not update subsidies', error }, { root: true });
        throw new Error(error);
      }
    },

    async updateParents({ dispatch }, parents) {
      try {
        await updateParents(parents);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not update parents', error }, { root: true });
        throw new Error(error);
      }
    },

    async updateChildren({ dispatch }, children) {
      try {
        await updateChildren(children);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not update child', error }, { root: true });
        throw new Error(error);
      }
    },
  },
};
