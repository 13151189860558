import { axios, toCamelCase, toSnakeCase } from '@utils';

const BASE_PATH = '/child_care_facilities/';

async function fetchPrograms(facilityId) {
  const { data } = await axios.get(
    `${BASE_PATH}${facilityId}/facility_programs.json`,
  );
  return toCamelCase(data);
}

async function addProgram(facilityId, program) {
  const { data } = await axios.post(
    `${BASE_PATH}${facilityId}/facility_programs.json`,
    toSnakeCase(program),
  );
  return toCamelCase(data);
}

async function fetchProgramDetails(facilityId, programId) {
  const { data } = await axios.get(
    `${BASE_PATH}${facilityId}/facility_programs/${programId}.json`,
  );
  return toCamelCase(data);
}

async function updateProgram(facilityId, program) {
  const { data } = await axios.put(
    `${BASE_PATH}${facilityId}/facility_programs/${program.id}.json`,
    toSnakeCase(program),
  );
  return toCamelCase(data);
}

function removeProgram(facilityId, programId) {
  return axios.delete(`${BASE_PATH}${facilityId}/facility_programs/${programId}.json`);
}

async function fetchSchedules(facilityId) {
  const { data } = await axios.get(`${BASE_PATH}${facilityId}/facility_program_schedule_types.json`);
  return toCamelCase(data);
}

export {
  fetchPrograms,
  updateProgram,
  addProgram,
  removeProgram,
  fetchProgramDetails,
  fetchSchedules,
};
