import {
  providerOptinPaidLeads, removeLead,
} from './leads.service';

export default {
  namespaced: true,
  state: {
    optedInPaidLeads: false,
  },
  getters: {
    optedInPaidLeads(state) {
      return state.optedInPaidLeads;
    },
  },
  mutations: {
    setOptedInPaidLeads: (state, optedIn) => {
      state.optedInPaidLeads = optedIn;
    },
  },
  actions: {
    async removeLead({ dispatch, commit }, { centerId, childId }) {
      try {
        const data = await removeLead({ centerId, childId });
        if (data.success) {
          commit('families/removeFamily', childId, { root: true });
          dispatch('notifications/addToastNotification', { text: 'Lead removed' }, { root: true });
        } else {
          dispatch('notifications/addToastError', { text: data.error }, { root: true });
        }
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Failed to remove lead', error }, { root: true });
      }
    },
    async providerOptinPaidLeads({ dispatch, commit }, paymentMethodId) {
      try {
        const data = await providerOptinPaidLeads(paymentMethodId);
        if (data.success) {
          commit('setOptedInPaidLeads', true);
          dispatch('notifications/addToastNotification', { text: 'Success! Opted-in leads' }, { root: true });
        } else {
          dispatch('notifications/addToastError', { text: data.error }, { root: true });
        }
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Failed to optin', error }, { root: true });
      }
    },
  },
};
