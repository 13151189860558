// import { addArrayToDictionary, getBoundsFromFacilities, toCamelCase } from '@utils';
import {
  fetchCalendars,
  deleteCalendar,
} from './calendars.service';

export default {
  namespaced: true,
  state: {
    calendars: [],
  },
  getters: {
    getCalendars: (state) => state.calendars,
  },
  mutations: {
    setCalendars(state, calendars) {
      state.calendars = calendars;
    },
    deleteCalendar(state, id) {
      state.calendars = state.calendars.filter((calendar) => calendar.id !== id);
    },
  },
  actions: {
    async fetchCalendars({ commit, dispatch }) {
      try {
        const calendars = await fetchCalendars();
        commit('setCalendars', calendars);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not load calendars', error }, { root: true });
        throw new Error();
      }
    },
    async deleteCalendar({ commit, dispatch }, id) {
      try {
        await deleteCalendar(id);
        commit('deleteCalendar', id);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not delete calendar', error }, { root: true });
        throw new Error();
      }
    },
  },
};
