import { axios, toCamelCase, toSnakeCase } from '@utils';

async function fetchPaymentRequests(facilityId) {
  const response = await axios.get(`/api/v1/payment_requests?child_care_facility_id=${facilityId}`);
  return toCamelCase(response.data);
}

async function createPayMeRequest(params) {
  const response = await axios.post('/api/v1/payment_requests', toSnakeCase(params));
  return toCamelCase(response);
}

async function searchExistingParents(params) {
  const urlParams = new URLSearchParams({ ...params, name: params.input }).toString();
  const { data } = await axios.get(`/api/v1/families/search_by_name?${urlParams}`);
  return toCamelCase(data);
}

async function createOneOffCharge(params) {
  const response = await axios.post('/api/v1/payment_requests/one_off_charge', toSnakeCase(params));
  return toCamelCase(response);
}

export {
  // eslint-disable-next-line
  fetchPaymentRequests,
  createPayMeRequest,
  searchExistingParents,
  createOneOffCharge,
};
