import {
  removeAvatar,
  updateNotificationSettings,
  getUser,
  updateUser,
  getUserNavData,
  getActionItems,
  confirmNewFeeStructure,
  fetchSiteWideSearchResults,
} from './provider-user.service';

export default {
  namespaced: true,

  state: {
    current: {},
    pages: [],
    luUser: false,
    actionItemsCount: 0,
    siteWideSearchResults: [],
    navbarExpanded: localStorage.getItem('navbarExpanded') ? localStorage.getItem('navBarExpanded') === 'true' : true,
  },

  getters: {
    avatar: (state) => state.current.avatar || state.current.luAvatar,
    pages: (state) => state.pages,
    luUser: (state) => state.luUser,
    navbarExpanded: (state) => state.navbarExpanded,
    actionItemsCount: (state) => state.actionItemsCount,
    isNewFeesVersion(state) {
      return state.current.isNewFeesVersion === undefined
      || state.current.isNewFeesVersion === null ? true : state.current.isNewFeesVersion;
    },
    siteWideSearchResults: (state) => state.siteWideSearchResults,
    currentUser: (state) => state.current,
  },

  mutations: {
    current: (state, payload) => {
      state.current = {
        ...payload,
        luAvatar: state.current.luAvatar,
      };
    },

    updateNotificationSettings(state, settings) {
      state.current = { ...state.current, emailSettings: settings };
    },

    clearAvatar(state) {
      state.current = { ...state.current, avatar: undefined };
    },

    navData: (state, payload) => {
      state.pages = payload.pages || [];
      state.current = { ...state.current, luAvatar: payload.avatar };
    },

    setLuUser: (state, payload) => {
      state.luUser = payload;
    },

    actionItemsCount: (state, payload) => {
      state.actionItemsCount = payload;
    },
    setIsNewFeesVersion: (state, payload) => {
      state.current.isNewFeesVersion = payload;
    },
    setSiteWideSearchResults: (state, payload) => {
      state.siteWideSearchResults = payload;
    },
    toggleNavbar: (state) => {
      state.navbarExpanded = !state.navbarExpanded;
      localStorage.setItem('navbarExpanded', state.navbarExpanded);
    },
  },

  actions: {
    setCurrentUser({ commit }, user) {
      commit('current', user);
      // eslint-disable-next-line no-underscore-dangle
      this._vm.$setupUserAccess(user);
    },

    setNavData({ commit }, data) {
      commit('navData', data);
    },

    setActionItemsCount({ commit }, actionItems) {
      const count = actionItems ? ['offers', 'questions', 'tours', 'requestPaperwork', 'sentPaperwork']
        .map((key) => actionItems[key])
        .reduce((acc, items) => acc.concat(items || []), [])
        .length : 0;
      commit('actionItemsCount', count);
    },

    async fetchCurrent({ dispatch, state }) {
      try {
        const user = await getUser();
        dispatch('setCurrentUser', user);
        dispatch('track/identifyUser', {
          id: state.current.id,
          traits: state.current,
        }, { root: true });
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not load user', error }, { root: true });
      }
    },

    async fetchSiteWideSearchResults({ commit, dispatch }, { query }) {
      try {
        const results = await fetchSiteWideSearchResults({ query });
        commit('setSiteWideSearchResults', results);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not fetch provider search results', error }, { root: true });
      }
    },

    async update({ dispatch, state }, userObj) {
      try {
        const user = await updateUser(userObj);
        dispatch('setCurrentUser', user);
        dispatch('track/identifyUser', {
          id: state.current.id,
          traits: state.current,
        }, { root: true });
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not update user', error }, { root: true });
      }
    },

    async removeAvatar({ commit, dispatch }) {
      try {
        await removeAvatar();
        commit('clearAvatar');
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not remove avatar', error }, { root: true });
        throw new Error();
      }
    },

    async updateNotificationSettings({ commit, dispatch }, settings) {
      try {
        await updateNotificationSettings(settings);
        commit('updateNotificationSettings', settings);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not update notification settings', error }, { root: true });
        throw new Error();
      }
    },

    async fetchNavData({ dispatch }, email) {
      try {
        const data = await getUserNavData(email);
        dispatch('setNavData', data);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not load navigation', error }, { root: true });
      }
    },

    async fetchActionItems({ dispatch }) {
      try {
        const actionItems = await getActionItems();
        if (actionItems.success) {
          dispatch('setActionItemsCount', actionItems);
        }
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not load action items', error }, { root: true });
      }
    },

    async confirmNewFeeStructure({ commit, dispatch }, params) {
      try {
        const response = await confirmNewFeeStructure(params.id);
        commit('setIsNewFeesVersion', response.success);
        commit('providerFees/setFeesSettings', response.providerFees, { root: true });
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not acknowledge notification', error }, { root: true });
      }
    },
  },
};
