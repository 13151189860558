import Vue from 'vue';
import Vuex from 'vuex';
import bankAccounts from '@stores/modules/bankAccounts';
import notifications from '@stores/modules/notifications';
import facilities from '@stores/modules/facilities';
import track from '@stores/modules/track';
import tours from '@stores/modules/tours';
import account from '@stores/modules/account';
import hubspot from '@services/hubspot.service';
import { fetchFacilities } from '@stores/modules/facilities.service';
import payments from '@stores/modules/payments';
import messaging from '@stores/modules/messaging';
import dependents from '@stores/modules/dependents';
import { toCamelCase } from '@utils';
import calendars from '@stores/modules/calendars';
import {
  searchFacilityByLicense,
  getStreetAddressFrom,
  searchFacilitiesByAddress,
  getFacilityPaymentHistory,
} from './care-provider.service';
import user from './modules/provider-user';
import availability from './modules/availability';
import enrollments from './modules/enrollments';
import legupEnrollments from './modules/legup-enrollments';
import paymentRequests from './modules/payment_requests';
import providerBusiness from './modules/provider-business';
import providerFees from './modules/provider-fees';
import programs from './modules/programs';
import offers from './modules/offers';
import waitlist from './modules/waitlist';
import facility from '../../common/stores/modules/facility';
import providerPlan from './modules/provider-plan';
import legup from '../../common/stores/modules/legup';
import tourSchedules from './modules/tour-schedules';
import legupTours from './modules/legup-tours';
import billingPlans from './modules/billing-plans';
import families from './modules/families';
import aiInquiries from './modules/ai-inquiries';
import paymentHistory from './modules/payment-history';
import leads from './modules/leads';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    account,
    aiInquiries,
    availability,
    bankAccounts,
    calendars,
    dependents,
    enrollments,
    facilities,
    facility,
    families,
    legup,
    legupEnrollments,
    legupTours,
    messaging,
    notifications,
    offers,
    paymentRequests,
    payments,
    programs,
    providerBusiness,
    providerFees,
    providerPlan,
    tours,
    tourSchedules,
    billingPlans,
    track,
    user,
    waitlist,
    paymentHistory,
    leads,
  },
  state: {
    isInitialized: false,
    facilityId: undefined,
    providerId: null,
    paymentSource: false,
    paymentSourceFacilityId: undefined,
    onboardingLocation: null,
    isMobile: false,
    waitlistSpotExport: false,
    enrollmentsExport: false,
    toursExport: false,
    familyExport: false,
    providerPaymentRequests: [],
  },
  mutations: {
    setInitialized: (state) => {
      state.isInitialized = true;
    },

    setFacilityId(state, facilityId) {
      state.facilityId = facilityId;
    },

    setProviderId(state, providerId) {
      state.providerId = providerId;
    },

    setPaymentSource(state, paymentSource) {
      state.paymentSource = paymentSource;
    },

    setPaymentSourceFacilityId(state, facilityId) {
      state.paymentSourceFacilityId = facilityId;
    },

    setOnboardingLocation(state, location) {
      state.onboardingLocation = location;
    },

    setIsMobile(state, value) {
      state.isMobile = value;
    },

    setWaitlistSpotExport(state, waitlistSpotExport) {
      state.waitlistSpotExport = waitlistSpotExport;
    },

    setEnrollmentsExport(state, enrollmentsExport) {
      state.enrollmentsExport = enrollmentsExport;
    },

    setFamilyExport(state, familyExport) {
      state.familyExport = familyExport;
    },

    setToursExport(state, toursExport) {
      state.toursExport = toursExport;
    },

    setProviderPaymentRequests(state, providerPaymentRequests) {
      state.providerPaymentRequests = providerPaymentRequests;
    },
  },
  actions: {
    async init({ dispatch, commit }) {
      await dispatch('user/fetchCurrent');
      commit('setInitialized');
    },

    isInitialized({ state }) {
      return new Promise((resolve) => {
        const checkInitializedState = () => {
          setTimeout(() => {
            if (state.isInitialized) {
              resolve();
            } else {
              checkInitializedState();
            }
          }, 300);
        };
        if (state.isInitialized) {
          resolve();
        } else {
          checkInitializedState();
        }
      });
    },

    openChat: () => hubspot.open(),

    async searchFacilityByLicense({ dispatch }, { licenses, state }) {
      try {
        return await searchFacilityByLicense(licenses, state);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not search by license number', error }, { root: true });
        return undefined;
      }
    },

    async searchFacilitiesByAddress({ dispatch }, searchParams) {
      try {
        return await searchFacilitiesByAddress(searchParams);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not search by address', error }, { root: true });
        return undefined;
      }
    },

    async getFacilityPaymentHistory({ dispatch, commit }, { facilityId, startDate, endDate }) {
      try {
        const newPaymentRequests = await getFacilityPaymentHistory(facilityId, startDate, endDate);
        commit('setProviderPaymentRequests', newPaymentRequests.paymentRequests);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not load payment history', error }, { root: true });
      }
    },

    async getStreetAddressFrom({ dispatch }, { lat, long }) {
      try {
        const location = await getStreetAddressFrom(lat, long);
        return location;
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not fetch address from coordinates', error }, { root: true });
        return undefined;
      }
    },

    async getFacilityById({ dispatch }, id) {
      try {
        const [facilityData] = await fetchFacilities({ idsToRequest: [id] }, { limited: true });
        return toCamelCase(facilityData);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not get facility data', error }, { root: true });
        return undefined;
      }
    },

    async setOnboardingLocation({ commit }, location) {
      commit('setOnboardingLocation', location);
    },
  },
});
