import {
  axios, toCamelCase, toSnakeCase,
} from '@utils';

const BASE_URL = '/api/v1/legup/enrollments';

async function fetchEnrollments(facilityId, search, page, rowsPerPage, sortBy, sortDirection) {
  const { data } = await axios.get(BASE_URL, {
    params: {
      facility_id: facilityId,
      rows_per_page: rowsPerPage,
      sort_by: sortBy,
      sort_direction: sortDirection,
      search,
      page,
    },
  });
  return toCamelCase(data);
}

async function fetchEnrollment(enrollmentId) {
  const url = `${BASE_URL}/${enrollmentId}`;
  const { data } = await axios.get(url);
  return toCamelCase(data);
}

async function enroll(enrollmentId, buttonLocation) {
  const url = `${BASE_URL}/${enrollmentId}/enroll`;
  const { data } = await axios.put(url, {
    button_location: buttonLocation,
  });
  return toCamelCase(data);
}

async function inviteToReview(enrollmentId, buttonLocation) {
  const url = `${BASE_URL}/${enrollmentId}/invite_to_review`;
  const { data } = await axios.put(url, {
    button_location: buttonLocation,
  });
  return toCamelCase(data);
}

async function unenroll(enrollmentId, returnChild, lastDay, buttonLocation) {
  const url = `${BASE_URL}/${enrollmentId}/unenroll`;
  const { data } = await axios.put(url, {
    return_to_waitlist: returnChild,
    last_day: lastDay,
    button_location: buttonLocation,
  });
  return toCamelCase(data);
}

async function cancelEnrollmentOffer(enrollmentId, returnChild, buttonLocation) {
  const url = `${BASE_URL}/${enrollmentId}/cancel`;
  const { data } = await axios.put(url, {
    return_to_waitlist: returnChild,
    button_location: buttonLocation,
  });
  return toCamelCase(data);
}

async function inviteToAutopay(enrollmentId, params) {
  const url = `${BASE_URL}/${enrollmentId}/invite_to_autopay`;
  const { data } = await axios.put(url, toSnakeCase(params));
  return toCamelCase(data);
}

async function exportEnrollments(facilityId, search, exportId) {
  const url = `${BASE_URL}/export`;
  await axios.get(url, {
    params: { facility_id: facilityId, search, export_id: exportId },
  });
}

async function searchByName(params) {
  const { data } = await axios.get(`${BASE_URL}/search_by_name`, { params: toSnakeCase(params) });

  return data;
}

async function editEnrollment(enrollmentId, amount, startDate, endDate, frequency, autopay) {
  const url = `${BASE_URL}/${enrollmentId}`;
  const { data } = await axios.put(url, toSnakeCase({
    enrollment: {
      amount, startDate, endDate, frequency,
    },
    autopay,
  }));
  return toCamelCase(data);
}

async function editLastEnrollmentDay(enrollmentId, lastDay) {
  const url = `${BASE_URL}/${enrollmentId}/update_unenrollment_day`;
  const { data } = await axios.put(url, { last_day: lastDay });
  return toCamelCase(data);
}

async function fetchParentInfo(parentId) {
  const response = await axios.get(`/api/v1/parent_users/parent_info?parent_id=${parentId}`);
  return toCamelCase(response.data);
}

async function fetchAutoPayConfirmationInfo(params) {
  const { data } = await axios.get(`${BASE_URL}/autopay_confirmation_info`, { params: toSnakeCase(params) });
  return toCamelCase(data);
}

// eslint-disable-next-line
export {
  fetchEnrollments, unenroll, enroll, inviteToReview, cancelEnrollmentOffer,
  inviteToAutopay, exportEnrollments, searchByName, editEnrollment,
  editLastEnrollmentDay, fetchEnrollment, fetchParentInfo,
  fetchAutoPayConfirmationInfo,
};
