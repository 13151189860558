/* eslint-disable no-shadow */
import {
  fetchPaymentRequests,
  createPayMeRequest,
  searchExistingParents,
  createOneOffCharge,
} from './payment_requests.service';

export default {
  namespaced: true,
  state: {
    paymentRequests: {
      amount_pending_cents: Number,
      amount_enrollments_pending_cents: Number,
      payment_requests: [{
        id: Number,
        child_care_facility_id: Number,
        provider_id: Number,
        amount: Number, // cents
        date_from: String, // start of care, can be empty
        date_to: String, // end of care, can be empty
        dependent_ids: [Number], // can be empty
        aasm_state: String, // full list of statuses here: app/models/payment_request.rb#32
      }],
      requestModalOpen: false,
    },
    existingParents: [],
  },
  getters: {
    totalPendingPaymentsInDollars(state) {
      if (state.paymentRequests.amountPendingCents) {
        const totalInDollars = state.paymentRequests.amountPendingCents / 100;
        return `${totalInDollars}`;
      }
      return '0';
    },
  },
  mutations: {
    setPaymentRequests: (state, paymentRequests) => {
      state.paymentRequests = paymentRequests;
    },
    toggleRequestModal: (state, isOpen) => {
      state.requestModalOpen = isOpen;
    },
    setExistingParents: (state, parents) => {
      state.existingParents = parents;
    },
  },
  actions: {
    async fetchPaymentRequests({ commit, dispatch, rootState }) {
      try {
        if (!rootState.facilityId) {
          return;
        }
        const paymentRequests = await fetchPaymentRequests(rootState.facilityId);
        commit('setPaymentRequests', paymentRequests);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not fetch payment requests', error }, { root: true });
      }
    },
    async createPayMeRequest({ commit, dispatch }, data) {
      try {
        commit('toggleRequestModal', false);
        const response = await createPayMeRequest(data);
        const { parentName } = response.data;
        dispatch(
          'notifications/addToastSuccess',
          { text: `Your payment request to ${parentName} has been sent!` },
          { root: true },
        );
      } catch (error) {
        const { response } = error;
        if (response.status === 422 && response.data.errors) {
          dispatch('notifications/addToastError', { text: response.data.errors[0] }, { root: true });
          commit('toggleRequestModal', true);
        } else {
          dispatch('notifications/addToastError', { text: "Couldn't create request", error }, { root: true });
        }
      }
    },
    async searchExistingParents({ commit, dispatch }, searchParam) {
      if (!searchParam || searchParam.length < 2) return;

      try {
        const { results } = await searchExistingParents(searchParam);
        commit('setExistingParents', results);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not fetch parents', error }, { root: true });
      }
    },
    async createOneOffCharge({ dispatch }, data) {
      try {
        const response = await createOneOffCharge(data);
        dispatch(
          'notifications/addToastSuccess',
          { text: 'Your one-off charge has been created!' },
          { root: true },
        );
        return response.data.paymentRequestIds;
      } catch (error) {
        const { response } = error;
        if (response.status === 422 && response.data.message) {
          dispatch('notifications/addToastError', { text: response.data.message }, { root: true });
        } else {
          dispatch('notifications/addToastError', { text: "Couldn't create one-off charge", error }, { root: true });
        }
      }

      return null;
    },
  },
};
