import { axios } from '@utils';

const BASE_URL = '/api/v1/child_care_facilities';

async function fetchFacilityOffers(facilityId) {
  const { data } = await axios.get(`${BASE_URL}/${facilityId}/facility_offers`);
  return data.offers;
}

async function createFacilityOffer(facilityId, facilityOffer) {
  const url = `${BASE_URL}/${facilityId}/facility_offers`;
  const { data } = await axios.post(url, {
    facility_offer: facilityOffer,
  });
  return data;
}

async function updateFacilityOffer(facilityId, facilityOffer) {
  const url = `${BASE_URL}/${facilityId}/facility_offers/${facilityOffer.id}`;
  const { data } = await axios.put(url, {
    facility_offer: facilityOffer,
  });
  return data;
}

async function deleteFacilityOffer(facilityId, facilityOfferId) {
  const url = `${BASE_URL}/${facilityId}/facility_offers/${facilityOfferId}`;
  const { data } = await axios.delete(url);
  return data;
}

export {
  createFacilityOffer,
  updateFacilityOffer,
  fetchFacilityOffers,
  deleteFacilityOffer,
};
