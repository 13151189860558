import { axios, toCamelCase, toSnakeCase } from '@utils';
import moment from 'moment-timezone';

async function fetchHistory({
  facilityId, searchText, page, rowsPerPage, sortBy, sortDirection,
  category, startDate, endDate, status,
}) {
  const { data } = await axios.post(`/api/v1/child_care_facilities/${facilityId}/payments_history`, toSnakeCase({
    rowsPerPage,
    sortBy,
    sortDirection,
    searchText,
    page,
    category,
    startDate,
    endDate,
    status,
  }));
  return toCamelCase(data);
}

async function fetchCurrentFailedPayments(facilityId) {
  const { data } = await axios.get(`/api/v1/child_care_facilities/${facilityId}/current_month_failed_payments`, {
    params: {
      start_date: moment().startOf('month').format('YYYY-MM-DD'),
      end_date: moment().endOf('month').format('YYYY-MM-DD'),
    },
  });
  return data;
}

async function dismissFailedPaymentsAlert(facilityId) {
  const { data } = await axios.put(`/api/v1/child_care_facilities/${facilityId}/dismiss_failed_payments_alert`, {
    start_date: moment().startOf('month').format('YYYY-MM-DD'),
    end_date: moment().endOf('month').format('YYYY-MM-DD'),
  });
  return data;
}

// eslint-disable-next-line
export { fetchHistory, fetchCurrentFailedPayments, dismissFailedPaymentsAlert };
