import {
  axios, toCamelCase, toSnakeCase, getEnvVar, getLocationObjFromGooglePlace,
} from '@utils';

async function searchFacilityByLicense(licenses, state) {
  const { data } = await axios.get('/childcare/by_licenses.json', {
    params: {
      state,
      license_numbers: licenses.join(','),
    },
  });
  return toCamelCase(data.facilities[0]);
}

async function getStreetAddressFrom(lat, long) {
  const { data } = await axios.get(
    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=${getEnvVar('GOOGLE_API_KEY')}`,
    {
      transformRequest: (body, headers) => {
        // eslint-disable-next-line no-param-reassign
        delete headers['X-CSRF-Token'];
      },
    },
  );

  if (!data.error_message) return getLocationObjFromGooglePlace(data.results[0]);

  return null;
}

async function searchFacilitiesByAddress(searchParams) {
  const { data } = await axios.get('/childcare/by_address.json', {
    params: toSnakeCase(searchParams),
  });
  return toCamelCase(data.facilities);
}

async function getFacilityPaymentHistory(facilityId, startDate, endDate) {
  const { data } = await axios.post(`/api/v1/child_care_facilities/${facilityId}/payments_history`, {
    date_from: startDate,
    date_to: endDate,
  });
  return toCamelCase(data);
}

// eslint-disable-next-line
export { searchFacilityByLicense, getStreetAddressFrom, searchFacilitiesByAddress, getFacilityPaymentHistory };
