import { groupBy } from 'lodash';

import {
  fetchTours,
  exportTours,
  searchByName,
  createOpenHouse,
  updateOpenHouse,
  cancelOpenHouse,
  fetchNewToursCount,
  markSeen,
  createTour,
  inviteToOpenHouse,
} from './legup-tours.service';

export default {
  namespaced: true,
  state: {
    tours: [],
    searchResult: [],
    page: 1,
    total: 0,
    rowsPerPage: 20,
    tourSchedulesSet: false,
    newToursCount: 0,
  },
  getters: {
    tours(state) {
      return state.tours;
    },

    upcomingTours: (state) => (count) => {
      const tours = count ? state.tours.slice(0, count) : state.tours;
      return groupBy(tours, (tour) => tour.startTime.split('T')[0]);
    },
  },
  mutations: {
    setTours(state, tours) {
      state.tours = tours;
    },
    setPage: (state, page) => {
      state.page = page;
    },
    setTotal: (state, total) => {
      state.total = total;
    },
    setRowsPerPage: (state, rows) => {
      state.rowsPerPage = rows;
    },
    setSearchResults: (state, result) => {
      state.searchResult = result;
    },
    setTourSchedulesSet: (state, isSet) => {
      state.tourSchedulesSet = isSet;
    },
    setNewToursCount: (state, count) => {
      state.newToursCount = count;
    },
  },
  actions: {
    async fetchTours({ dispatch, commit, state }, {
      centerId, searchText, sortBy, sortDirection, pastTours, upcomingTours,
    }) {
      try {
        const {
          tours, page, total, tourSchedulesSet,
        } = await fetchTours({
          centerId,
          searchText,
          page: state.page,
          rowsPerPage: state.rowsPerPage,
          sortBy,
          sortDirection,
          pastTours,
          upcomingTours,
        });
        commit('setTours', tours);
        commit('setTotal', total);
        commit('setPage', page);
        commit('setTourSchedulesSet', tourSchedulesSet);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not load tours', error }, { root: true });
      }
    },

    async searchByName({ commit, dispatch }, params) {
      try {
        const { results } = await searchByName(params);
        commit('setSearchResults', results);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not search.', error }, { root: true });
      }
    },

    async exportTours({ dispatch }, params) {
      try {
        await exportTours(params);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not export tours', error }, { root: true });
        throw new Error(error);
      }
    },

    async createOpenHouse({ dispatch }, params) {
      try {
        await createOpenHouse(params);
        dispatch(
          'notifications/addToastSuccess',
          { text: 'New open house scheduled' },
          { root: true },
        );
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not create open house', error }, { root: true });
        throw new Error(error);
      }
    },

    async updateOpenHouse({ dispatch }, params) {
      try {
        await updateOpenHouse(params);
        dispatch(
          'notifications/addToastSuccess',
          { text: 'Open house updated' },
          { root: true },
        );
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not update open house', error }, { root: true });
        throw new Error(error);
      }
    },

    async createTour({ dispatch }, params) {
      try {
        await createTour(params);
        dispatch(
          'notifications/addToastSuccess',
          { text: 'New tour scheduled' },
          { root: true },
        );
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not create tour', error }, { root: true });
        throw new Error(error);
      }
    },

    async fetchNewToursCount({ dispatch, commit }) {
      try {
        const data = await fetchNewToursCount();
        commit('setNewToursCount', data?.count);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could fetch new tours count', error }, { root: true });
        throw new Error(error);
      }
    },

    async cancelOpenHouse({ dispatch }, params) {
      try {
        await cancelOpenHouse(params);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not cancel open house', error }, { root: true });
        throw new Error(error);
      }
    },

    async markSeen({ commit, dispatch }, centerId) {
      try {
        const response = await markSeen({ centerId });
        if (response.status !== 200) {
          dispatch('notifications/addToastError', { text: 'Could not mark tours as seen' }, { root: true });
        }
        commit('setNewToursCount', response.data?.count);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not mark tours as seen', error }, { root: true });
      }
    },

    async inviteToOpenHouse({ dispatch }, params) {
      try {
        await inviteToOpenHouse(params);
        dispatch(
          'notifications/addToastSuccess',
          { text: 'Open house invite sent' },
          { root: true },
        );
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not send open house invite', error }, { root: true });
        throw new Error();
      }
    },
  },
};
