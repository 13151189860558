/* eslint-disable no-shadow */
import {
  fetchProviderInfo,
  saveProviderInfo,
} from './provider-business.service';

const state = {
  provider: {
    id: Number,
    provider_name: String,
    tin: String,
    tin_type: String,
    address: String,
    phone_number: String,
    phone_number_formatted: String,
    first_name: String,
    last_name: String,
    email: String,
    orum_status: String,
    orum_status_message: String,
    bank_account: Object,
    accept_kinside_payment: Boolean,
    payment_acceptance_method: String,
    company_name: String,
    website: String,
    provider_type: String,
    full_address: String,
  },
};

const getters = {
  providerBusinessInfo: (state) => state.provider,
};

const mutations = {
  setProviderBusinessInfo: (state, provider) => {
    state.provider = provider;
  },
};

const actions = {
  async fetchProviderBusinessInfo({ commit, dispatch }, id) {
    if (!id) {
      dispatch('notifications/addToastError', { text: 'Could not fetch provider business info' }, { root: true });
      return;
    }

    try {
      const businessInfo = await fetchProviderInfo(id);
      commit('setProviderBusinessInfo', businessInfo);
    } catch (error) {
      dispatch('notifications/addToastError', { text: 'Could not fetch provider business info', error }, { root: true });
    }
  },

  async saveProviderBusinessInfo({ dispatch }, providerData) {
    try {
      await saveProviderInfo(providerData);
    } catch (error) {
      dispatch('notifications/addToastError', { text: 'Could not save provider business info', error }, { root: true });
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
