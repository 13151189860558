import { axios, toCamelCase } from '@utils';
import qs from 'qs';

async function getWaitlistSpots(params) {
  try {
    const { data } = await axios.get('/api/v1/legup/waitlist_spots', {
      params,
      paramsSerializer: () => qs.stringify(params, { arrayFormat: 'brackets' }),
    });

    return toCamelCase(data);
  } catch (error) {
    throw new Error(error);
  }
}

async function getWaitlistSpotDetails(id) {
  try {
    const { data } = await axios.get(`/api/v1/legup/waitlist_spots/${id}`);

    return toCamelCase(data);
  } catch (error) {
    throw new Error(error);
  }
}

async function sendWaitlistInvite(params) {
  const centerId = params.id;

  const { data } = await axios.post(`/api/v1/legup/centers/${centerId}/invite_family_to_waitlist`, params);

  return toCamelCase(data);
}

async function searchSpotsByName(params) {
  const { data } = await axios.get('/api/v1/legup/waitlist_spots/search_by_name', { params });

  return data;
}

async function fetchOfferPreview(params) {
  const centerId = params.center_id;

  const { data } = await axios.get(`/api/v1/legup/centers/${centerId}/offer_preview`, params);

  return toCamelCase(data);
}

async function fetchAgeGroupsAndPrograms(facilityId) {
  const { data } = await axios.get(`/api/v1/legup/centers/${facilityId}/age_groups_and_programs`);

  return toCamelCase(data);
}

async function markUnresponsive(id) {
  try {
    const { data } = await axios.put(`/api/v1/legup/waitlist_spots/${id}/unresponsive`);

    return toCamelCase(data);
  } catch (error) {
    throw new Error(error);
  }
}

async function reactivate(id) {
  try {
    const { data } = await axios.put(`/api/v1/legup/waitlist_spots/${id}/reactivate`);

    return toCamelCase(data);
  } catch (error) {
    throw new Error(error);
  }
}

async function markEnrolled(id) {
  try {
    const { data } = await axios.put(`/api/v1/legup/waitlist_spots/${id}/enrolled`);

    return toCamelCase(data);
  } catch (error) {
    throw new Error(error);
  }
}

async function exportWaitlistSpots(centerId, exportId) {
  await axios.get('api/v1/legup/waitlist_spots/export', { params: { center_id: centerId, export_id: exportId } });
}

export {
  getWaitlistSpots,
  getWaitlistSpotDetails,
  sendWaitlistInvite,
  fetchOfferPreview,
  fetchAgeGroupsAndPrograms,
  searchSpotsByName,
  markUnresponsive,
  markEnrolled,
  exportWaitlistSpots,
  reactivate,
};
