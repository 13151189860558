import {
  fetchFacilityOffers,
  createFacilityOffer,
  updateFacilityOffer,
  deleteFacilityOffer,
} from './offers.service';

const defaultEmptyOffer = {
  id: null,
  title: null,
  amount: null,
  amount_type: 'dollars',
  offer_type: 'waived_registration',
  ongoing_unit: 'day',
  ongoing_duration: null,
  description: null,
  provider_notes: null,
};

export default {
  namespaced: true,
  state: {
    offers: [],
    selectedOffer: defaultEmptyOffer,
  },
  mutations: {
    setOffers: (state, offers) => {
      state.offers = offers;
    },

    resetSelectedOffer(state) {
      const newOffer = { ...defaultEmptyOffer };
      newOffer.id = state.offers.length + 1;
      state.selectedOffer = { ...newOffer };
    },

    setSelectedOffer(state, offerId) {
      const newOffer = state.offers.filter((offer) => offerId === offer.id)[0];
      state.selectedOffer = { ...newOffer };
    },
  },
  actions: {
    async fetchOffers({ commit, dispatch }, facilityId) {
      try {
        const offers = await fetchFacilityOffers(facilityId);
        commit('setOffers', offers);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not fetch offers', error }, { root: true });
      }
    },

    async removeOffer({ commit, state }, { facilityId, facilityOfferId }) {
      const newOffers = state.offers.filter((offer) => facilityOfferId !== offer.id);
      await deleteFacilityOffer(facilityId, facilityOfferId);
      commit('setOffers', newOffers);
    },

    async saveOffer({ commit, dispatch, state }, facilityId) {
      try {
        const offerToSave = { ...state.selectedOffer };
        const { offers } = state;
        const hasExisitingOffer = offers.filter((offer) => offer.id === offerToSave.id).length > 0;
        let newOffers = [];

        if (hasExisitingOffer) {
          // existing offer
          const savedOffer = await updateFacilityOffer(facilityId, offerToSave);
          newOffers = state.offers.map((offer) => {
            if (offer.id === savedOffer.id) {
              return savedOffer;
            }
            return offer;
          });
        } else {
          // new offer
          const savedOffer = await createFacilityOffer(facilityId, offerToSave);
          newOffers = [...state.offers, savedOffer];
        }
        commit('setOffers', newOffers);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not save offers', error }, { root: true });
      }
    },
  },
};
