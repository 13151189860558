import {
  submitResponse,
} from './ai-inquiries.service';

export default {
  namespaced: true,

  state: {
    responseSubmitted: false,
  },

  actions: {
    async submitResponse({ dispatch, state }, formData) {
      try {
        const response = await submitResponse(formData);
        if (response.success) {
          state.responseSubmitted = true;
        } else {
          dispatch('notifications/addToastError', {
            text: response.message,
          }, { root: true });
        }
      } catch (error) {
        dispatch('notifications/addToastError', {
          text: 'Could not submit response',
          error,
        }, { root: true });
      }
    },
  },
};
