import { axios } from '@utils';

async function fetchPaymentMethods() {
  const response = await axios.get('/payment_methods.json');
  return response.data;
}

async function addBankAccountPaymentMethod(publicToken, accountId) {
  const response = await axios.post(
    '/payment_methods/bank_account.json',
    {
      public_token: publicToken,
      account_id: accountId,
    },
  );
  return response.data;
}

export {
  fetchPaymentMethods,
  addBankAccountPaymentMethod,
};
