import { addArrayToDictionary } from '@utils';
import {
  fetchPrograms,
  updateProgram,
  addProgram,
  removeProgram,
  fetchProgramDetails,
  fetchSchedules,
} from './programs.service';

export default {
  namespaced: true,
  state: {
    all: {},
    schedules: {},
  },
  getters: {
    allAsArray: (state) => (Object.values(state.all) || [])
      .sort((p1, p2) => p1.position - p2.position),
    schedulesAsArray: (state) => Object.values(state.schedules) || [],
  },
  mutations: {
    addPrograms: (state, programs) => {
      state.all = { ...addArrayToDictionary(programs, state.all) };
    },
    updateProgram: (state, program) => {
      state.all = { ...state.all, [program.id]: { ...state.all[program.id], ...program } };
    },
    removeProgram: (state, id) => {
      const { [id]: removed, ...rest } = state.all;
      state.all = rest;
    },
    setSchedules: (state, schedules) => {
      state.schedules = { ...addArrayToDictionary(schedules, state.schedules) };
    },
  },
  actions: {
    async fetchPrograms({
      commit, rootState, getters, dispatch,
    }) {
      try {
        if (getters.allAsArray.length || !rootState.facilityId) {
          return;
        }
        const programs = await fetchPrograms(rootState.facilityId);
        commit('addPrograms', programs);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not fetch programs', error }, { root: true });
      }
    },

    async addProgram({ rootState, commit, dispatch }, program) {
      try {
        if (!rootState.facilityId) {
          return;
        }
        const addedProgram = await addProgram(rootState.facilityId, program);
        commit('addPrograms', [addedProgram]);
        dispatch('notifications/addToastNotification', { text: `Program "${program.name}" added succesfully` }, { root: true });
      } catch (error) {
        dispatch('notifications/addToastError', { text: `Program "${program.name}" has not been added`, error }, { root: true });
      }
    },

    async fetchProgramDetails({ rootState, commit, dispatch }, programId) {
      try {
        if (!rootState.facilityId && programId === undefined) {
          return;
        }
        const program = await fetchProgramDetails(rootState.facilityId, programId);
        commit('updateProgram', program);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Program details have not been fetched', error }, { root: true });
      }
    },

    async updateProgram({ rootState, commit, dispatch }, program) {
      try {
        if (!rootState.facilityId) {
          return;
        }
        await updateProgram(rootState.facilityId, program);
        commit('updateProgram', program);
        dispatch('notifications/addToastNotification', { text: `Program "${program.name}" updated succesfully` }, { root: true });
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Program has not been updated', error }, { root: true });
      }
    },

    async reOrderProgram({ rootState, commit, dispatch }, { program, direction }) {
      let reOrderedProgram;

      if (direction === 'up') {
        reOrderedProgram = { ...program, position: program.position - 1 };
      } else if (direction === 'down') {
        reOrderedProgram = { ...program, position: program.position + 1 };
      }

      try {
        if (!rootState.facilityId) {
          return;
        }
        await updateProgram(rootState.facilityId, reOrderedProgram);
        const programs = await fetchPrograms(rootState.facilityId);
        commit('addPrograms', programs);
        dispatch(
          'notifications/addToastNotification',
          { text: `Program "${program.name}" order updated sucessfully` },
          { root: true },
        );
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Program has not been reordered', error }, { root: true });
      }
    },

    async removeProgram({
      rootState, commit, state, dispatch,
    }, programId) {
      try {
        if (!rootState.facilityId) {
          return;
        }
        const { name } = state.all[programId];
        await removeProgram(rootState.facilityId, programId);
        commit('removeProgram', programId);
        dispatch('notifications/addToastNotification', { text: `Program "${name}" removed succesfully` }, { root: true });
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Program has not been removed', error }, { root: true });
      }
    },

    async fetchSchedules({
      rootState, commit, getters, dispatch,
    }) {
      try {
        if (getters.schedulesAsArray.length || !rootState.facilityId) {
          return;
        }
        const schedules = await fetchSchedules(rootState.facilityId);
        commit('setSchedules', schedules);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not fetch schedules', error }, { root: true });
      }
    },
  },
};
