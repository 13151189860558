import {
  fetchBillingPlans,
  search,
  updateBillingPlan,
  fetchBillingPlan,
  dismissSetupAlert,
} from './billing-plans.service';

export default {
  namespaced: true,
  state: {
    billingPlans: [],
    searchResult: [],
    page: 1,
    total: 0,
    rowsPerPage: 20,
    plansWithUnsetNextCharge: {},
  },
  getters: {
    billingPlans(state) {
      return state.billingPlans;
    },
  },
  mutations: {
    setBillingPlans(state, billingPlans) {
      state.billingPlans = billingPlans;
    },
    updateBillingPlan(state, { id, billingPlan }) {
      const index = state.billingPlans.findIndex((b) => b.id === id);
      if (index !== -1) {
        state.billingPlans.splice(index, 1, billingPlan);
      }
    },
    setSearchResults: (state, result) => {
      state.searchResult = result;
    },
    setPage: (state, page) => {
      state.page = page;
    },
    setTotal: (state, total) => {
      state.total = total;
    },
    setRowsPerPage: (state, rows) => {
      state.rowsPerPage = rows;
    },
    setPlansWithUnsetNextCharge: (state, plansWithUnsetNextCharge) => {
      state.plansWithUnsetNextCharge = plansWithUnsetNextCharge;
    },
  },
  actions: {
    async fetchBillingPlans({ dispatch, commit, state }, {
      facilityId, searchText, sortBy, sortDirection, filter,
    }) {
      try {
        const {
          paymentCollections, page, total, plansWithUnsetNextCharge,
        } = await fetchBillingPlans(
          facilityId,
          searchText,
          filter,
          state.page,
          state.rowsPerPage,
          sortBy,
          sortDirection,
        );

        commit('setBillingPlans', paymentCollections);
        commit('setTotal', total);
        commit('setPage', page);
        commit('setPlansWithUnsetNextCharge', plansWithUnsetNextCharge || {});
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not load billing plans', error }, { root: true });
      }
    },
    async search({ commit, dispatch }, params) {
      try {
        const { results } = await search(params);
        commit('setSearchResults', results);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not search.', error }, { root: true });
      }
    },

    async fetchBillingPlan({ dispatch }, id) {
      let data = null;
      try {
        data = await fetchBillingPlan(id);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Failed to fetch billing plan', error }, { root: true });
      }
      return data;
    },
    async updateBillingPlan({ commit, dispatch }, { id, params }) {
      let data = {};
      try {
        data = await updateBillingPlan(id, params);
        if (data.id) {
          commit('updateBillingPlan', { id: data.id, billingPlan: data });
          dispatch('notifications/addToastNotification', { text: 'Success! The billing plan has been updated.' }, { root: true });
        } else {
          dispatch('notifications/addToastError', { text: data.errors }, { root: true });
        }
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Failed to update', error }, { root: true });
      }
      return data;
    },
    async dismissSetupAlert({ commit, dispatch }, { billingIds, reInvite }) {
      try {
        await dismissSetupAlert(billingIds, reInvite);
        commit('setPlansWithUnsetNextCharge', {});
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Failed to dismiss', error }, { root: true });
      }
    },
  },
};
